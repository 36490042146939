import React, { useState } from 'react'
import './Astro.css'
// import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
const Appointment = () => {
   const [Name, setName] = useState("");
   const [Email, setEmail] = useState("");
   const [mobilenumber, setMobilenumber] = useState("");
   const [Gender, setGender] = useState("");
   const [timeofday, setTimeofday] = useState("");
   const [Phone, setPhone] = useState("");
   const [date, setDate] = useState("");
   const [Time, setTime] = useState("");
   const [address, setAddress] = useState("");
   const [Reason, setReason] = useState("");

   const handleSubmit = async (e) => {
      e.preventDefault();
      let item = {
         Name,
         Email,
         mobilenumber,
         Gender,
         timeofday,
         Phone,
         date,
         Time,
         address,
         Reason,
       }

      try {
         const res = await axios.post(
           "",
           item
         );
         console.log(res.status);
         console.log(res);
       } catch (e) {
         console.log(item);
         console.log(e, "Something Went Wrong");
       }
   }
  return (
    <>
<div className="Main_Appointment_bg_col">
         <div className="container">
            <div className="Main_Appointment_content">
               <h3>Make Your Appointment To Discuss Any Problem</h3>
               <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.
               </p>
               <p>It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem ipsum is therefore always free from repetition,injected humour, or non-characteristic words etc.</p>
            </div>
            <div className="form-main-sct">
               <div className="form-main-sct_cnt">
                  <h3>Appointment Form</h3>
               </div>
               <form className="row mg-tp" method="post">
                  <div className="col-sm-6">
                     <div className="form-main-sct_input">
                        <p>Name</p>
                        <input
                         onChange={(e) => {
                           let value = e.target.value;
                           setName(e.target.value);
                           if (!value) {
                             setName("");
                           } 
                         }}
                         value={Name}
                        type="Name" name="Name" placeholder="Name" className="form-control"/>
                     </div>
                     <div className="form-main-sct_input">
                        <p>Mobile Number</p>
                        <input  onChange={(e) => {
                           let value = e.target.value;
                           setMobilenumber(e.target.value);
                           if (!value) {
                             setMobilenumber("");
                           } 
                         }}  value={mobilenumber} type="Name" name="mobilenumber" placeholder="Mobile Number" className="form-control"/>
                     </div>
                     <div className="form-main-sct_input">
                        <p>Time Of Day</p>
                        <input onChange={(e) => {
                           let value = e.target.value;
                           setTimeofday(e.target.value);
                           if (!value) {
                             setTimeofday("");
                           } 
                         }}  value={timeofday} type="Name" name="timeofday" placeholder="Morning" className="form-control"/>
                     </div>
                     <div className="form-main-sct_input">
                        <p>Preferred Date</p>
                        <div className="row">
                           <div className="col-sm-4">
                              <input
                               onChange={(e) => {
                                 let value = e.target.value;
                                 setDate(e.target.value);
                                 if (!value) {
                                   setDate("");
                                 } 
                               }}
                               value={date} type="Name" name="date" placeholder="Date" className="form-control"/>
                           </div>
                           <div className="col-sm-4">
                              <input
                            type="Name" name="date" placeholder="Month" className="form-control"/>
                           </div>
                           <div className="col-sm-4">
                              <input 
                           type="Name" name="date" placeholder="Year" className="form-control"/>
                           </div>
                        </div>
                     </div>
                     <div className="form-main-sct_input">
                        <p>Address</p>
                        <input
                        onChange={(e) => {
                           let value = e.target.value;
                           setAddress(e.target.value);
                           if (!value) {
                             setAddress("");
                           } 
                         }}
                        value={address} type="Name" name="address" placeholder="Name" className="form-control" style={{height: "120px" , paddingBbottom:"75px"}}/>
                     </div>
                  </div>
                  <div className="col-sm-6">
                     <div className="form-main-sct_input">
                        <p>Email</p>
                        <input
                         onChange={(e) => {
                           let value = e.target.value;
                           setEmail(e.target.value);
                           if (!value) {
                             setEmail("");
                           } 
                         }}
                        value={Email} type="Name" name="Email" placeholder="Email" className="form-control"/>
                     </div>
                     <div className="form-main-sct_input">
                        <p>Gender</p>
                        <input
                         onChange={(e) => {
                           let value = e.target.value;
                           setGender(e.target.value);
                           if (!value) {
                             setGender("");
                           } 
                         }}
                        value={Gender} type="Name" name="Gender" placeholder="Male" className="form-control"/>
                     </div>
                     <div className="form-main-sct_input">
                        <p>Way To Reach</p>
                        <input 
                          onChange={(e) => {
                           let value = e.target.value;
                           setPhone(e.target.value);
                           if (!value) {
                             setPhone("");
                           } 
                         }}
                        value={Phone} type="Name" name="Phone" placeholder="Phone" className="form-control"/>
                     </div>
                     <div className="form-main-sct_input">
                        <p>Preferred Time</p>
                        <div className="row">
                           <div className="col-sm-4">
                              <input
                              
                              onChange={(e) => {
                                 let value = e.target.value;
                                 setTime(e.target.value);
                                 if (!value) {
                                   setTime("");
                                 } 
                               }}
                              value={Time} type="Name" name="Time" placeholder="Hrs" className="form-control"/>
                           </div>
                           <div className="col-sm-4">
                              <input
                              
                              type="Name" name="Time" placeholder="Mins" className="form-control"/>
                           </div>
                           <div className="col-sm-4">
                              <input 
                              
                             type="Name" name="Time" placeholder="Sec" className="form-control"/>
                           </div>
                        </div>
                        <div className="form-main-sct_input">
                           <p>Reason For Appointment</p>
                           <input 
                            onChange={(e) => {
                              let value = e.target.value;
                              setReason(e.target.value);
                              if (!value) {
                                setReason("");
                              } 
                            }}
                           value={Reason} type="Name" name="Reason" placeholder="Message" className="form-control" style={{height: "120px" , paddingBottom:"75px"}}/>
                        </div>
                        <div className="Appointment_main_button">
                           <button type="button"  onClick={handleSubmit} >MAKE AN APPOINTMENT</button>
                        </div>
                     </div>
                  </div>
               </form>

               
            </div>
         </div>
      </div>
    </>
  )
}
export default Appointment