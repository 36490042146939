import React from 'react'
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { ListItem, Stack } from "@mui/material";
import Img from "../../assets/Images/Imgs";
import './footer.css'
import { FaFacebook, FaGooglePlus, FaTwitter, FaYoutube } from 'react-icons/fa';

const Footer  = () => {
  return (
    <Box
      sx={{
        width:"100%",
        height:"auto",
        backgroundColor: "#031d2e",
        paddingTop: "1rem",
        paddingBottom: "1rem",
      }}
    >
     
  <Container maxWidth="xl">
  <div style={{ color:'#fff' }}>

  <Stack direction="row" spacing={2}>
  <Stack spacing={2}>
  <ListItem>
  <img src={Img.Logo} className="Astro-img" alt="" />  </ListItem>
  <ListItem  sx={{    flex: '0 0 auto',
    width: '65%'}} className='text'> Consectetur adipiscing elited doesde eiusmod tempor incididunt ust labore et dolore magna aliqua.</ListItem>
  <ListItem>   <FaTwitter size={30} color="#fff" className="social-icon" />
    <FaFacebook size={30} color="#fff" className="social-icon" />
    <FaGooglePlus size={30} color="#fff" className="social-icon" />
    <FaYoutube size={30} color="#fff" className="social-icon" />
    </ListItem>

  </Stack>
  <Stack direction="row" spacing={2} >
  <ListItem>
<ul className='Unorderd_cnt'>
   <li><h5> Our Services</h5> </li>
   <li> Horoscopes </li> 
   <li> Gemstones</li> 
   <li> Numerology </li> 
   <li> Tarot Cards</li> 
   <li> Birth Journal </li> 
</ul>

  </ListItem>
  </Stack>
  <Stack direction="row" spacing={2}>
  <ListItem>
<ul className='Unorderd_cnt'>
<li><h5>Quick Links</h5> </li>
   <li> Horoscopes </li> 
   <li> Gemstones</li> 
   <li> Numerology </li> 
   <li> Tarot Cards</li> 
   <li> Birth Journal </li> 
</ul>

  </ListItem>
  </Stack>
  <Stack direction="row" spacing={2} >
  <ListItem>
<ul className='Unorderd_cnt'>
<li><h5>Contact Us</h5> </li>
   <li> Horoscopes </li> 
   <li> Gemstones</li> 
   <li> Numerology </li> 
   <li> Tarot Cards</li> 
   <li> Birth Journal </li> 
</ul>
  </ListItem>
  </Stack>
  </Stack>
  </div>

  </Container>

    </Box>
  )
}

export default Footer