import React from 'react';
import Our_services from '../our_services/Our_services';
import WhyChooseUs from '../whychooseUs/WhyChooseUs';
import Newsletter from '../../component/newsletter/Newsletter';
import WhatClientSay from '../whatClientSay/WhatClientSay';
import Footer from '../../component/Footer/Footer';

const Services = () => {
  return (
    <div>
      <Our_services></Our_services>
      <WhyChooseUs></WhyChooseUs>
      <WhatClientSay></WhatClientSay>
      <Newsletter></Newsletter>
      
    </div>
  )
}

export default Services
