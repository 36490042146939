import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import "./our-services-card.css";
import { Link } from "react-router-dom";

// const bull = (
//   <Box
//     component="span"
//     sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
//   >
//     •
//   </Box>
// );

export default function BasicCard(props: { service_content: any; }) {


  return (
    <>
      <Card sx={{ minWidth: 275 }} className="as_service_box">
        <span className="as_icon">
          <img src={props?.service_content.Imgs} alt="" />
        </span>
        <CardContent>
          <Typography
          key={props?.service_content.name}
            sx={{ fontSize: 14 }}
            color="text.secondary"
            gutterBottom 
            className="as_subheading"
          >
         {props?.service_content.name}
          </Typography>
          <Typography variant="h5" component="div" className="as_typo_text">
           {props?.service_content.text}
          </Typography>
        </CardContent>
        <CardActions style={{textAlign:'center', justifyContent:'center'}}>
          {/* <Button onClick={()=>handleclick()} size="small" className="as_link">Learn More</Button> */}
          <Link className="as_link"  to="/Homelearnmore">Learn More</Link>
        </CardActions>
      </Card>
    </>
  );
}