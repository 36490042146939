import Img from "../assets/Images/Imgs";

  const service_contents = [
  {
    name: "Birth Journal",
    Imgs: Img.ServicesImage2,
    text: "Consectetur adipiscing elit sed do eiusmod tempor incididunt.",
  },
  {
    name: "Manglik Dosha",
    Imgs: Img.ServicesImage3,
    text: "Consectetur adipiscing elit sed do eiusmod tempor incididunt.",
  },
  {
    name: 'Kundli Dosh',
    Imgs:  Img.ServicesImage6,
    text: 'Consectetur adipiscing elit sed do eiusmod tempor incididunt.',
  },
  {
    name: 'Palm Reading',
    Imgs:  Img.ServicesImage8,
    text: 'Consectetur adipiscing elit sed do eiusmod tempor incididunt.',
  },
];
export default service_contents;