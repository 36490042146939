import React from "react";
import { Row, Col } from "react-grid-system";
import Container from "@mui/material/Container/Container";
import "./whychooseus.css";
import Img from "../../assets/Images/Imgs";

const WhyChooseUs = () => {
  return (
    <div className="as_whychoose_wrapper ">
      <Container maxWidth={"xl"}>
        <Row className="chooseus_row"> 
          <Col sm={3} className="col-lg-3 col-md-12">
            <h1 className="as_heading">Why Choose Us</h1>
            <p className="as_font14 as_margin0">
              Consectetur adipiscing elit, sed do eiusmod tempor
              incididuesdeentiut.
            </p>
          </Col>
          <Col sm={9} className="col-lg-9 col-md-12">
            <Row className="row chooseus_inner_row">
              <Col className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                <div className="as_whychoose_box text-center">
                  <span className="as_number">
                    <span>
                      <span data-from="0" data-to="512" data-speed="5000">
                        512
                      </span>
                      +
                    </span>
                    <img src={Img.ShapeImage} alt="" />
                  </span>
                  <h4>Qualified Astrologers</h4>
                </div>
              </Col>
              <Col className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                <div className="as_whychoose_box text-center">
                  <span className="as_number">
                    <span>
                      <span data-from="0" data-to="62" data-speed="5000">
                        62
                      </span>
                      +
                    </span>
                    <img src={Img.ShapeImage} alt="" />
                  </span>
                  <h4>Success Horoscope</h4>
                </div>
              </Col>
              <Col className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                <div className="as_whychoose_box text-center">
                  <span className="as_number">
                    <span>
                      <span data-from="0" data-to="94" data-speed="5000">
                        94
                      </span>
                      +
                    </span>
                    <img src={Img.ShapeImage} alt="" />
                  </span>
                  <h4>Offices Worldwide</h4>
                </div>
              </Col>
              <Col className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                <div className="as_whychoose_box text-center">
                  <span className="as_number">
                    <span>
                      <span data-from="0" data-to="452" data-speed="5000">
                        452+
                      </span>
                      +
                    </span>
                    <img src={Img.ShapeImage} alt="" />
                  </span>
                  <h4>Trust by million clients</h4>
                </div>
              </Col>
              <Col className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                <div className="as_whychoose_box text-center">
                  <span className="as_number">
                    <span>
                      <span data-from="0" data-to="12" data-speed="5000">
                        12
                      </span>
                      +
                    </span>
                    <img src={Img.ShapeImage} alt="" />
                  </span>
                  <h4>Year experience</h4>
                </div>
              </Col>
              <Col className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                <div className="as_whychoose_box text-center">
                  <span className="as_number">
                    <span>
                      <span data-from="0" data-to="652" data-speed="5000">
                        652+
                      </span>
                      +
                    </span>
                    <img src={Img.ShapeImage} alt="" />
                  </span>
                  <h4>Type of horoscopes</h4>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default WhyChooseUs;
