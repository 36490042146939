import  Logo from '../Images/all_images/logo.svg';
import bannerImage from '../Images/all_images/banner_image.png';
import BackgroundImageOne from '../Images/all_images/bg1.jpg';
import Arrow from '../Images/all_images/arrow.png';
import AboutImage1 from '../Images/all_images/about1.jpg';
import AboutImage2 from '../Images/all_images/about2.jpg';
import AboutImage3 from '../Images/all_images/about3.jpg';
import AboutImage4 from '../Images/all_images/about4.jpg';
import ServicesImage1 from '../Images/all_images/service1.svg';
import ServicesImage2 from '../Images/all_images/service2.svg'
import ServicesImage3 from '../Images/all_images/service3.svg'
import ServicesImage4 from '../Images/all_images/service4.svg'
import ServicesImage5 from '../Images/all_images/service5.svg'
import ServicesImage6 from '../Images/all_images/service6.svg'
import ServicesImage7 from '../Images/all_images/service7.svg'
import ServicesImage8 from '../Images/all_images/service8.svg'
import CustomerImage1 from '../Images/all_images/customer1.jpg'
import CustomerImage2 from '../Images/all_images/customer2.jpg'
import qouteImg1 from '../Images/all_images/quote1.svg'
import qouteImg2 from '../Images/all_images/quote2.svg'
import ShapeImage from '../Images/all_images/shape.svg'
import Loader from '../Images/all_images/loader.png'
import ZodiacImg from '../Images/all_images/zodiac.png'

 const Img = {
    Logo,
    ZodiacImg,
    bannerImage,
    BackgroundImageOne,
    Arrow,
    AboutImage1,
    AboutImage2,
    AboutImage3,
    AboutImage4,
    ServicesImage1,
    ServicesImage2,
    ServicesImage3,
    ServicesImage4,
    ServicesImage5,
    ServicesImage6,
    ServicesImage7,
    ServicesImage8,
    CustomerImage1,
    CustomerImage2,
    qouteImg1,
    qouteImg2,
    ShapeImage,
    Loader,
}

export default Img;