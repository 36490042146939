import React, { useState, useEffect } from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import About from "../src/pages/about/About";

import Header from "../src/component/menu/Header.tsx";
// import AnimateImage from "../src/component/Image_animation/AnimateImage";
import Home from "../src/pages/home/Home";
import Services from "./pages/services/Services";
import Contact from "../src/pages/contact/Contact";
import Homelearnmore from "./component/our_services_card/Learnmore";
import './component/our_services_card/Astro.css';
import Blog from '../src/pages/blogs/Blog';
import  Appointment from '../src/component/Appointment';


function App() {
  return (
    <div className="App">
      <Header></Header>
      <Routes>
        <Route exact={true} path="/" element={<Home />} />
      </Routes>
      <Routes>
        <Route path="/about" element={<About />} />
      </Routes>
      <Routes>
        <Route path="/services" element={<Services />}/>
      </Routes>
      <Routes>
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Routes>
      <Route path="/Homelearnmore" element={<Homelearnmore/>}/>
      </Routes>
      <Routes>
      <Route path="/blog" element={<Blog/>}/>
      </Routes>
      <Routes>
      <Route path="/appointment" element={<Appointment/>}/>
      </Routes>
    </div>
  );
}

export default App;
