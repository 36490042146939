import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../Slick_slider/slider.css";
import { Container} from "@mui/material";
import AnimateImage from "../Image_animation/AnimateImage";
// import 'bootstrap/dist/css/bootstrap.min.css';  
import {Row, Col} from 'react-bootstrap'  
import '../Slick_slider/slider.css'
import './slider.css'
const SlickSlider = () => {
  const slider_contents = [
    {
      id: 1,
      Sign: "What are these stars ?",
      heading1: "Read Your Daily",
      heading2: "Horoscope Today",
      paragraph:
        "Consectetur adipiscing elit, sed do eiusmod tempor incididuesdeentiut labore etesde dolore magna aliquapspendisse and the gravida.",
    },
    {
      id: 2,
      Sign: "Why the stars fall ?",
      heading1: "Read Your Daily",
      heading2: "Horoscope Today",
      paragraph:
        "Consectetur adipiscing elit, sed do eiusmod tempor incididuesdeentiut labore etesde dolore magna aliquapspendisse and the gravida.",
    },
    {
      id: 3,
      Sign: " what are tail stars ?",
      heading1: "Read Your Daily",
      heading2: "Horoscope Today",
      paragraph:
        "Consectetur adipiscing elit, sed do eiusmod tempor incididuesdeentiut labore etesde dolore magna aliquapspendisse and the gravida.",
    },
    {
      id: 4,
      Sign: " why they disappear in a sometime ?",
      heading1: "Read Your Daily",
      heading2: "Horoscope Today",
      paragraph:
        "Consectetur adipiscing elit, sed do eiusmod tempor incididuesdeentiut labore etesde dolore magna aliquapspendisse and the gravida.",
    },
    {
      id: 5,
      Sign: "Why Sun rises from east?  ",
      heading1: "Read Your Daily",
      heading2: "Horoscope Today",
      paragraph:
        "Consectetur adipiscing elit, sed do eiusmod tempor incididuesdeentiut labore etesde dolore magna aliquapspendisse and the gravida.",
    },
    {
      id: 6,
      Sign: "Why season come according to order?",
      heading1: "Read Your Daily",
      heading2: "Horoscope Today",
      paragraph:
        "Consectetur adipiscing elit, sed do eiusmod tempor incididuesdeentiut labore etesde dolore magna aliquapspendisse and the gravida.",
    },
    {
      id: 7,
      Sign: " what are tail stars ?",
      heading1: "Read Your Daily",
      heading2: "Horoscope Today",
      paragraph:
        "Consectetur adipiscing elit, sed do eiusmod tempor incididuesdeentiut labore etesde dolore magna aliquapspendisse and the gravida.",
    },
    {
      id: 8,
      Sign: " why they disappear in a sometime ?",
      heading1: "Read Your Daily",
      heading2: "Horoscope Today",
      paragraph:
        "Consectetur adipiscing elit, sed do eiusmod tempor incididuesdeentiut labore etesde dolore magna aliquapspendisse and the gravida.",
    },
  ];

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 20000,
  };
  return (
    <div>
      <Container maxWidth={"xl"}>
       {/* <Row className="bg-primary m-5 p-5 astro_slider_row">  */}
       <Row className="m-5 p-5 astro_slider_row">
          <Slider {...settings}>
            {slider_contents.map((slidercontent, index) => (
              <Col>
                <div className="as_banner_detail"  key={slidercontent.id}>
                  <h5 className="as_orange">{slidercontent.Sign}</h5>
                  <h1>{slidercontent.heading1}</h1>
                  <h1>{slidercontent.heading2}</h1>
                  <p>{slidercontent.paragraph}</p>
                  {/* <a href="#" className="as_btn">
                    read more
                  </a> */}
                </div>
              </Col>
            ))}
          </Slider>
          <Col>
          <AnimateImage></AnimateImage>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SlickSlider;
