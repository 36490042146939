import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import AdbIcon from "@mui/icons-material/Adb";
import Img from "../../assets/Images/Imgs";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import HeadsetMicOutlinedIcon from "@mui/icons-material/HeadsetMicOutlined";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import { Link } from "react-router-dom";
import Switch  from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { styled } from "@mui/material/styles";
// import  '../menu/header.scss'
import "../menu/header.css";
import { ListItem, Stack } from "@mui/material";

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
    width: 32,
    height: 32,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));


const Header = () => {
  
  const [theme, setTheme] = useState("dark");
  const toggleTheme = () => {
    if (theme === "light") {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  };

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  return (
    <AppBar position="static" className="astro-navbar">
      <Container maxWidth={false} style={{ padding: 0 }}>
        <Toolbar disableGutters>
        <a href="/">
            {" "}<img className="as_logo" src={Img.Logo} alt="" />
            </a>

          <Stack style={{ width: "100%" }}>
            <ListItem className="as_info_detail">
              <Box
                sx={{ display: { xs: "none", md: "flex" } }}
                className="headset-svg"
              >
                <HeadsetMicOutlinedIcon></HeadsetMicOutlinedIcon>
              </Box>

              <Box sx={{ flexGrow: 0, pl: 2 }}>
                <Typography className="as_logintext">
                  Talk to our Astrologer -{" "}
                  <Link to="tel:+18003263263264">+support@website.com</Link>
                </Typography>
              </Box>
              <Box sx={{ display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
              </Box>
              <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} />
              <Typography
                variant="h5"
                noWrap
                component="a"
                href="#"
                sx={{
                  mr: 2,
                  display: { xs: "flex", md: "none" },
                  flexGrow: 1,
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                LOGO
              </Typography>

              <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
                <Button
                  sx={{ my: 2, color: "white", display: "block" }}
                ></Button>
              </Box>

              <Box
                sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}
                className="headset-svg"
              >
                <DraftsOutlinedIcon></DraftsOutlinedIcon>
              </Box>

              <Box sx={{ flexGrow: 0, pl: 2 }}>
                <Typography className="as_logintext">
                  Talk to our Astrologer -{" "}
                  <Link to="tel:+18003263263264">+support@website.com</Link>
                </Typography>
              </Box>

              <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                <Button
                  sx={{ my: 2, color: "white", display: "block" }}
                ></Button>
              </Box>

              <Box
                sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}
                className="headset-svg"
              >
                <PersonAddAltIcon></PersonAddAltIcon>
              </Box>

              <Box sx={{ flexGrow: 0 }}>
                <Typography sx={{ pl: 4 }} className="">
                  Log in / Register
                </Typography>
              </Box>
              <Box>
                <FormGroup>
                  <FormControlLabel
                    onClick={toggleTheme}
                    control={<MaterialUISwitch sx={{ m: 1 }} defaultChecked />}
                    label=""
                  />
                </FormGroup>
              </Box>
            </ListItem>

              <ListItem>
                <Box>
                   <div id="google_translate_element"></div>
                </Box>
              </ListItem>

            <ListItem
              className="as_menu_wrapper"
              sx={{ flexGrow: 3, display: "flex", justifyContent: "flex-end" }}
            >
              <Box sx={{ flexGrow: 0, p: 2 }}>
                <Link className="list-item" to="/">
                  Home
                </Link>
              </Box>
              <Box sx={{ flexGrow: 0, p: 2 }}>
                <Link className="list-item" to="/about">
                  About
                </Link>
              </Box>
              <Box sx={{ flexGrow: 0, p: 2 }}>
                <Link className="list-item" to="/services">
                  Services
                </Link>
              </Box>
              <Box sx={{ flexGrow: 0, p: 2 }}>
                <Link className="list-item" to="/blog">
                  Blog
                </Link>
              </Box>
              <Box sx={{ flexGrow: 0, p: 2 }}>
                <Link className="list-item" to="/appointment">
                  Appointment
                </Link>
              </Box>

              <Box sx={{ flexGrow: 0, pl: 2 }}>
                <Link className="list-item" to="/contact">
                  Contact
                </Link>
              </Box>
            </ListItem>
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
