import React from 'react'
import service_single from '../../assets/Images/all_images/service_single.jpg'
import s1 from '../../assets/Images/all_images/s1.jpg'
import s2 from '../../assets/Images/all_images/s2.jpg'
import s3 from '../../assets/Images/all_images/s3.jpg' 
import logo1 from '../../assets/Images/all_images/logo1.svg'
import gplay from '../../assets/Images/all_images/gplay.png'  
import appstore from '../../assets/Images/all_images/appstore.png'  
import Footer from '../Footer/Footer'
function Learnmore() {
    
  return (                             
    <>
    <div>
    <div class="Main_bg_clr">
          <div class="container">
              <div class="row">
                  <div class="col-sm-9">
                      <div class="Main_img_cnt">
                          <img src={service_single} alt="img" class="img-responsive"/>
                      </div>
                      <div class="Vastu_Shastra_para">
                        <h1>Vastu Shastra</h1>
                        <p class="Vastu_p mrgn_tp" >Consectetur adipiscing elit, sed do Lorem ipsum dolor sit amet, consectetur adipisicing eliit, sed do eiusimod tempor incididunt eiiut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exerceiitation ullamco laboris nisi ut aliquip exeea commodeo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum esidolore fugiat nula pariatur. Excepteur sint occaecat cupidatat non irure dolor in reprehenderit proident.</p>
                        <p class="Vastu_p">sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus eeserror sesiqihit voliuptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis eset quasi architecto beesiatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas seseit aspernatur aut odit aut fugit, seied quia consequuntur magni dolores eos quesdeii ratione voluptatem sequi nesciunt. Neque posesrro quisquam est, qui dolorem ipsum quesdeiia dolor sit amet, consectetur, adipisci velit, seesid quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem eius modi tempora incidunt ut labore et dolore magnam dolores eos quesdeii ratione .</p>
                  </div>
                  <div class="Tips_img_col">
                      <div class="Tips_img_col_1">
                      <img src={s1} alt="img" class="img-responsive"/>
                      </div>
                      <div class="Tips_img_col_2">
                          <img src={s2} alt="img" class="img-responsive"/>
                      </div>
                      <div class="Tips_img_col_3">
                          <img src={s3} alt="img" class="img-responsive"/>
                      </div>
                  </div>
                  <div class="Tips_img_col_para">
                      <p>Consectetur adipiscing elit, sed do Lorem ipsum dolor sit amet, consectetur adipisicing eliit, sed do eiusimod tempor incididunt eiiut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exerceiitation ullamco laboris nisi ut aliquip exeea commodeo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum esidolore fugiat nula pariatur. Excepteur sint occaecat cupidatat non irure dolor in reprehenderit proident.</p>
                  </div>
                  <div class="Tips_for_home_vastu_hdng">
                      <h4>Tips for home vastu shastra</h4>
                  </div>
                  <div class="Tips_for_home_vastu_hdng_para">
                      <p>sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus eeserror sesiqihit voliuptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis eset quasi architecto beesiatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas seseit aspernatur.</p>
                  </div>
                  <div class="row">
                      <div class="col-sm-6">
                          <ul class="ul_li_service_section">
                              <li>Sunt in culpa qui officia deserunt</li>
                              <li>Mollit anim id est laborum. Sed ut perspiciatis</li>
                              <li>Unde omnis iste natus eeserror sesiqihit</li>
                              <li>Voliuptatem accusantium doloremque laudantium</li>
                              <li>Totam rem aperiam eaque ipsa quae ab illo inventore</li>
                              <li>Veritatis eset quasi architecto.</li>
                          </ul>
                      </div>
                      <div class="col-sm-6">
                          <ul class="ul_li_service_section-Sec">
                              <li>Sunt in culpa qui officia deserunt</li>
                              <li>Mollit anim id est laborum. Sed ut perspiciatis</li>
                              <li>Unde omnis iste natus eeserror sesiqihit</li>
                              <li>Voliuptatem accusantium doloremque laudantium</li>
                              <li>Totam rem aperiam eaque ipsa quae ab illo inventore</li>
                              <li>Veritatis eset quasi architecto.</li>
                          </ul>
                      </div>
                  </div>
              </div>
              
              <div class="col-sm-3">
                  <div class="Residential_Vastu_cnt">
                      <h3>Residential Vastu</h3>
                  </div>
                  <ul class="ul_li_Residential_section">
                      <li>Bath Room<span>(210)</span></li>
                      <li>Bed Room<span>(62)</span></li>
                      <li>Study Room<span>(551)</span></li>
                      <li>Puja Room<span>(415)</span></li>
                      <li>Toilet<span>(52)</span></li>
                      <li>Guest Room<span>(75)</span></li>
                      <li>Dining Room<span>(20)</span></li>
                  </ul>
                  <div class="Commercial_Vastu_cnt">
                      <h3>Commercial  Vastu</h3>
                  </div>
                  <ul class="ul_li_Residential_section pdng_tp" >
                      <li>Offices<span>(210)</span></li>
                      <li>Shop/Showroom<span>(62)</span></li>
                      <li>Industry<span>(551)</span></li>
                      <li>Hotels<span>(415)</span></li>
                      <li>Institutions<span>(52)</span></li>
                      <li>Movie Halls<span>(75)</span></li>
                      <li>Factories<span>(20)</span></li>
                  </ul>
                  <div class="Download_app_section_main">
                      <div class="Img-astro_app_store">
                          
                          <img src={logo1} alt="logo" class="img-responsive"/>
                          
                          <h3>Download The App Now!</h3>
                          <div class="play_store_section">
                                <img src={gplay} alt="gplay_img" class="img-responsive"/>
                                  <img src={appstore} alt="appstore_img" class="img-responsive"/>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      </div><section class="Form_main_sect_bg_co">
              <div class="container">
                  <div class="Form_main_sect_bg_co_cnt">
                      <h1>Know Your Zodiac Sign</h1>
                      <p>Consectetur adipiscing elit, sed do eiusmod tempor incididuesdeentiut labore
                          etesde dolore magna aliquapspendisse and the gravida.
                      </p>
                  </div>
                  <div class="main_forn_sec_zodic_form">
                      <form class="row">
                          <div class="form-group col-lg-3 col-md-6">
                              <div class="zodic_form_info">
                                  <label for="Date Of Birth">Date Of Birth</label>
                                  <input type="date" class="form-control " id="input_clr" />
                              </div>
                          </div>
                          <div class="form-group col-lg-3 col-md-6">
                              <div class="zodic_form_info">
                                  <label for="Time Of Birth">Time Of Birth</label>
                                  <input type="text" class="form-control" id="input_clr" placeholder="08:00" />
                              </div>
                          </div>
                          <div class="form-group col-lg-3 col-md-6">
                              <div class="zodic_form_info">
                                  <label for="Place Of Birth">Place Of Birth</label>
                                  <input type="text" class="form-control" id="input_clr" placeholder="Enter City Name....." />
                              </div>
                          </div>
                          <div class="form-group col-lg-3 col-md-6">
                              <button type="button">FIND ZODIAC</button>
                          </div>
                      </form>
                  </div>
              </div>
              <div class="pt-4"></div>
          </section>
       </div>

       <Footer></Footer>
       </>
  )
}

export default Learnmore;