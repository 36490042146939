import React, {useState} from "react";
import "./contact.css";
import WifiCalling3SharpIcon from "@mui/icons-material/WifiCalling3Sharp";
import TelegramIcon from '@mui/icons-material/Telegram';
import { Container } from "@mui/material";
import { Row, Col } from "react-grid-system";
import Footer from "../../component/Footer/Footer";
import axios from "axios";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

interface Props {
   name?: string;
   email?: string;
   message?: string;
 }
 
const Contact = (props : Props) => {

   const [name, setName] = useState <Props | string> ();
   const [email, setEmail] = useState <Props | string>();
   const [message, setMessage] = useState <Props | string>();

   const handleSubmit = async (event: any) => {
      event.preventDefault();
      const errors = {};
      var len = Object.keys(errors).length;
      if (len === 0) {
      let item = {
         name,
         email,
         message
       }
       try{
         const res = await axios.post("", item)
         console.log(res.status);
         toast.success("Applied Success");
         console.log(res);
       } catch (e) {
         console.log(item);
         console.log(e, "Something Went Wrong");
         toast.error("Something Went Wrong");
       }
       }
      }
  return (
<div className="as_contact_section as_padderTop80">
   <Container>
      <Row className="row">

         <Col>
         <div className="as_contact_info">
            <div className="as_contact_info-txt">
               <h1 className="as_heading bdrBottom">Contact Information</h1>
               <p className="as_font14 as_margin0">
                  Consectetur adipiscing elit sed do eiusmod tr incididunt
                  <br /> ut labore et dolore magna aliquauis ipsum.
               </p>
            </div>
         </div>
         <Row>
            <Col className="col-xl-6 col-lg-12 col-md-6 col-sm-6 col-xs-12">
            <div className="as_info_box">
               <span className="as_icon">
                  <WifiCalling3SharpIcon></WifiCalling3SharpIcon>
               </span>
               <div className="as_info">
                  <h5>Call Us</h5>
                  <p className="as_margin0 as_font14">+ (91) 1800-124-105</p>
                  <p className="as_margin0 as_font14">+ (91) 1800-326-324</p>
               </div>
            </div>
            </Col>
            <Col className="col-xl-6 col-lg-12 col-md-6 col-sm-6 col-xs-12">
            <div className="as_info_box">
               <span className="as_icon">
                  <TelegramIcon></TelegramIcon>
               </span>
               <div className="as_info">
                  <h5>Mail Us</h5>
                  <p className="as_margin0 as_font14">
                     <a href="mailto:astrology@example.com"> astrology@example.com</a>
                  </p>
                  <p className="as_margin0 as_font14">
                     <a href="mailto:astrology@example.com">astro@example.com</a>
                  </p>
               </div>
            </div>
            </Col>
         </Row>
         </Col>
         <Col className="col-lg-6 col-md-12">
         <div className="as_contact_form">
            <h4 className="as_subheading">Have A Question?</h4>
            <form action="#" method="post">
               <div className="form-group">
                  <label>Full Name</label>
                  <input
                   onChange={(e) => {
                    let value = e.target.value;
                    setName( e.target.value)
                   }}
                   value={props.name}
                  type="text" name="name" id="" className="form-control" />
               </div>
               <div className="form-group">
                  <label>Email Address</label>
                  <input
                   onChange={(e) => {
                     let value = e.target.value;
                     setEmail( e.target.value)
                    }}
                    value={props.email}
                  type="text" name="email" id="" className="form-control" />
               </div>
               <div className="form-group">
                  <label>Message</label>
                  <textarea
                   onChange={(e) => {
                     let value = e.target.value;
                     setMessage( e.target.value)
                    }}
                    value={props.message}
                  name="message" id="" className="form-control-hght"></textarea> 
               </div>
               <button type="button" className="as_btn"  onClick={handleSubmit} >read more</button>
            </form>
         </div>
         </Col>
      </Row>
   </Container>
   
   <section className="contactMap_section">
      <div className="Map_sct">
         <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4078764.9924000916!2d-105.6681527148753!3d45.09540905234947!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2sin!4v1614404007812!5m2!1sen!2sin" height={740} width={100}></iframe>
      </div>
   </section>
   <div className="background-img-cont-bg">
      <Container>
         <Row className="contactNews-input">
            <Col className="col-xl-4 col-lg-12 col-md-4 col-sm-4 col-xs-12">
            <div className="background-img-cont">
               <h1 className="bdrBottom-letter">Our Newsletter</h1>
               <p className="">Get Your Daily Horoscope, Daily Lovescope And Daily Tarot Directly In Your Inbox</p>
            </div>
            </Col>
            <Col className="col-xl-8 col-lg-12 col-md-8 col-sm-8 col-xs-12">
            <div className="">
               <div className="as_newsletter_box">
                  <input type="text" placeholder="Type your Email Here...." name="search" className="form-control" />
                  <button type="submit" className="as_btn">SUBSCRIBE NOW </button>
               </div>
            </div>
            </Col>
         </Row>
      </Container>
   </div>

</div>
);
};
export default Contact;