import React from "react";
import Slider from "react-slick";
import "../Slick_slider/slider.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Img from "../../assets/Images/Imgs";
// import { Row, Col } from "react-bootstrap";
// import 'bootstrap/dist/css/bootstrap.min.css';
import {Row, Col } from "react-grid-system";
import Container from "@mui/material/Container/Container";
import WifiCalling3SharpIcon from '@mui/icons-material/WifiCalling3Sharp';
import './about_slider.css'

const AboutSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nav: false,
    autoplay: true,
    autoplaySpeed: 2000,
    // dotsClass: "button__bar",
  };

  return (
    <>
    <div className="about_bg">
    <Container maxWidth="xl">
      <Row>
        <Col lg={6}>
          <Slider {...settings}>
            <div className="item as_aboutimg text-right">
              <img src={Img.AboutImage1} alt="" />
            </div>
            <div className="item as_aboutimg text-right">
              <img src={Img.AboutImage2} alt="" />
            </div>
            <div className="item as_aboutimg text-right">
              <img src={Img.AboutImage3} alt="" />
            </div>
            <div className="item as_aboutimg text-right">
              <img src={Img.AboutImage4} alt="" />
            </div>
          </Slider>
        </Col>
        <Col lg={6}>
          <div className="about_right_text">
            <h1 className="as_heading">know about Astrology</h1>
            <p>
              t is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it esdehas a more-or less
              normal distribution of letters,
            </p>
            <p>
              As opposed to using 'Content here, content here', making it look
              likesdesdee readable English.Many desktop publishing packages and
              web page editors sdesnow use Lorem Ipsum as their default model
              text, and a search for 'lorem ipsum' will usdencover many web
              sites still in their web page editors sdesnow infancy.
            </p>
            <a href="#" className="as_btn">
              read more
            </a>

            <div className="as_contact_expert">
              <span className="as_icon">
               <WifiCalling3SharpIcon></WifiCalling3SharpIcon>
              </span>
              <div>
                <h5 className="as_white">Contact Our Expert Astrologers</h5>
                <h1 className="as_orange">+ (91) 1800-124-105</h1>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      </Container>
      </div>
    </>
  );
};

export default AboutSlider;
