import React, { useMemo, useState } from "react";
import { Grid } from "@mui/material";
import "../home/home.css";
import SlickSlider from "../../component/Slick_slider/Slider";
import AboutSlider from "../../component/about_slider/AboutSlider";
import Our_services from "../our_services/Our_services";
import Find_zodiac from "../../component/find_zodiac_form/Find_zodiac_form";
import "../../component/Slick_slider/slider.css";
import WhatClientSay from "../whatClientSay/WhatClientSay";
import WhyChooseUs from "../whychooseUs/WhyChooseUs";
import Footer from "../../component/Footer/Footer";
import Newsletter from "../../component/newsletter/Newsletter";
import Zodiac_sign from "../demo/Zodiac_sign";

const Home = () => {
  return (
    <>
      <div className="main_wrapper">
        <Grid container>
          <Grid item xs={12}>
            <SlickSlider></SlickSlider>
          </Grid>
        </Grid>
      </div>
      <AboutSlider></AboutSlider>
      <Our_services></Our_services>
      <Find_zodiac></Find_zodiac>
      <WhatClientSay></WhatClientSay>
      <WhyChooseUs></WhyChooseUs>
      <Newsletter></Newsletter>
      {/* <Zodiac_sign></Zodiac_sign> */}
      {/* <Footer></Footer> */}
    </>
  );
};

export default Home;