import React from 'react';
import AboutSlider from '../../component/about_slider/AboutSlider';
import WhyChooseUs from '../whychooseUs/WhyChooseUs';
import WhatClientSay from '../whatClientSay/WhatClientSay';
import Newsletter from '../../component/newsletter/Newsletter';
import Footer from '../../component/Footer/Footer';
import './about.css'

const About = () => {
  return (
    <div>
      <AboutSlider></AboutSlider>
      <WhyChooseUs></WhyChooseUs>
      <WhatClientSay></WhatClientSay>
      <Newsletter></Newsletter>
    </div>
  )
}

export default About;
