import React from "react";
import BasicCard from "../../component/our_services_card/Our_Services_card";
import { Row, Col } from "react-grid-system";
import Container from "@mui/material/Container/Container";
import "./our_services.css";
import data from "../../component/data";
const Our_services = () => {
  return (
    <>
      <div className="as_service_wrapper">
        <Container maxWidth="xl">
          <div className="col-lg-12 text-center">
            <h1 className="as_heading as_heading_center">our services</h1>
            <p className="as_font14 as_padderBottom5">
              Consectetur adipiscing elit, sed do eiusmod tempor
              incididuesdeentiut labore <br />
              etesde dolore magna aliquapspendisse and the gravida.
            </p>
          </div>
          <Row>
            {data.map((service) => (
              <Col lg={3}>
                <div className="wrapper">
                  <BasicCard 
                  
                   service_content={service} />
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Our_services;
