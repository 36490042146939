import React from "react";
import "./newsletter.css";
import { Container } from "@mui/material";
import { Row, Col } from "react-grid-system";

const Newsletter = () => {
  return (
    <div className="as_footer_wrapper">
      <Container maxWidth={"xl"}>
        <div className="as_newsletter_wrapper as_padderBottom60">
          <Row>
            <Col sm={5}>
              {" "}
              <div className=" col-md-12 col-sm-12">
                <h1 className="as_heading">Our Newsletter</h1>
                <p className="as_para">
                  Get Your Daily Horoscope, Daily Lovescope and Daily Tarot
                  Directly In Your Inbox
                </p>
              </div>
            </Col>
            <Col sm={7}>
              <div className=" col-md-12 col-sm-12">
                <div className="as_newsletter_box">
                  <input
                    type="text"
                    name=""
                    id=""
                    className="form-control"
                    placeholder="Enter your Email Here..."
                  />
                  {/* <a href="#" className="as_btn">
                    subscribe now
                  </a> */}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default Newsletter;
