import React from 'react'
import Img from '../../assets/Images/Imgs'
import '../Image_animation/animate_image.css'

const AnimateImage = () => {
  return (
    <div>
      <img className='rotate' src={Img.bannerImage} alt="" />
    </div>
  )
}

export default AnimateImage;
