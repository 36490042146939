import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../component/Slick_slider/slider.css";
import { Container } from "@mui/material";
import "../whatClientSay/whatclientsay.css";
import Img from "../../assets/Images/Imgs";
import { Row, Col } from "react-bootstrap";
const WhatClientSay = () => {
  const slider_contents = [
    {
      id: 1,
      ReviewText:
        "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravidaesdisus commodo viverra maecenas accumsan lacus vel facilisis. ",
      Images: Img.CustomerImage1,
      quotes: Img.qouteImg1,
      quotes2: Img.qouteImg2,
      Name: "David Parker",
      Designation: "Astrologer",
    },
    {
      id: 2,
      ReviewText:
        "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravidaesdisus commodo viverra maecenas accumsan lacus vel facilisis. ",
      Images: Img.CustomerImage2,
      quotes: Img.qouteImg1,
      quotes2: Img.qouteImg2,
      Name: "John Parker",
      Designation: "Astrologer",
    },
    {
      id: 3,
      ReviewText:
        "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravidaesdisus commodo viverra maecenas accumsan lacus vel facilisis. ",
      Images: Img.CustomerImage1,
      quotes: Img.qouteImg1,
      quotes2: Img.qouteImg2,
      Name: "David Parker",
      Designation: "Astrologer",
    },
    {
      id: 4,
      ReviewText:
        "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravidaesdisus commodo viverra maecenas accumsan lacus vel facilisis. ",
      Images: Img.CustomerImage2,
      quotes: Img.qouteImg1,
      quotes2: Img.qouteImg2,
      Name: "John Parker",
      Designation: "Astrologer",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 20000,
  };

  return (
    <div className="client_say_bg">
      <Container maxWidth="xl">
        <div className="col-lg-12 text-center">
          <h1 className="as_heading as_heading_center">
            What Our Customers Say
          </h1>
          <p className="as_font14 as_margin0 as_padderBottom50">
            Consectetur adipiscing elit, sed do eiusmod tempor
            incididuesdeentiut labore <br />
            etesde dolore magna aliquapspendisse and the gravida.
          </p>
        </div>
        <Slider {...settings}>
          {slider_contents.map((slidercontent, index) => (
            <div className="text-center" key={slidercontent.id}>
              <div className="as_customer_box ">
              <span className="as_customer_img">
                <img src={slidercontent.Images} alt="" />
                <span>
                  <img src={slidercontent.quotes} alt="" />
                </span>
              </span>
              <p className="as_margin0">{slidercontent.ReviewText}</p>
              <h3>{slidercontent.Name}</h3>
              <p className="as_margin0">{slidercontent.Designation}</p>
            </div>
            </div>
          ))}
        </Slider>
      </Container>
    </div>
  );
};

export default WhatClientSay;
