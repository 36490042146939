import React from 'react'
import "./blog.css"
import blog1 from '../../assets//Images//all_images/blog1.jpg'
import user from '../../assets//Images//all_images/user.svg'
import comment from '../../assets//Images//all_images/comment.svg'
import blog2 from '../../assets//Images//all_images/blog2.jpg'
import blog3 from '../../assets//Images//all_images/blog3.jpg'
import blog4 from '../../assets//Images//all_images/blog4.jpg'
import blog5  from '../../assets//Images//all_images/blog5.jpg'
import blog6 from '../../assets//Images//all_images/blog6.jpg'

function Blog() {
  return (
    <div>

        <div className="blog_main_bg_section_clr">
	<div className="container card_position">
		<div className="row">
			<div className="col-sm-12 col-xl-4 col-lg-4 col-md-6 ">
		
			<div className="Blog_main_img_card_section">
				<img src={blog1} className="img_responsive"/>
				<div className="Blog_main_btn_card">
					<button className="btn">JULY 29,2022</button>
				</div>
				<div className="Admin_img_and_Comments_main">
					<div className="Admin_img_and_Comments_First">
						<a href="#"><img src={user}/></a> <span>By-Admin</span>
					</div>
					<div className="Admin_img_and_Comments_second">
						<a href="#"><img src={comment}/></a> <span>0 comments</span>
					</div>
				</div>
				<div className="Content_sec_blog_head">
				<h3>Consectetur Adipiscing Elit Sedeius Mod Tempor Incididunt Ut Labore.</h3>
				</div>
                <div className="Content_sec_blog_para">
                	<p>Consectetur adipiscing elit, sed desdo eiusmod tempor incididuesdeentiut labore etesde doloesire esdesdeges magna aliquapspendisse and the gravida.</p>
                </div>
			</div>
		</div>

		<div className="col-sm-12 col-md-6 col-xl-4 col-lg-4  ">
			<div className="Blog_main_img_card_section">
				<img src={blog2} className="img_responsive"/>
				<div className="Blog_main_btn_card">
					<button className="btn">JULY 29,2022</button>
				</div>
				<div className="Admin_img_and_Comments_main">
					<div className="Admin_img_and_Comments_First">
						<a href="#"><img src={user}/></a> <span>By-Admin</span>
					</div>
					<div className="Admin_img_and_Comments_second">
						<a href="#"><img src={comment}/></a> <span>0 comments</span>
					</div>
				</div>
				<div className="Content_sec_blog_head">
				<h3>Consectetur Adipiscing Elit Sedeius Mod Tempor Incididunt Ut Labore.</h3>
				</div>
                <div className="Content_sec_blog_para">
                	<p>Consectetur adipiscing elit, sed desdo eiusmod tempor incididuesdeentiut labore etesde doloesire esdesdeges magna aliquapspendisse and the gravida.</p>
                </div>
			</div>
		</div>

		<div className="col-sm-12 col-md-6 col-xl-4 col-lg-4  ">
			<div className="Blog_main_img_card_section">
				<img src={blog3} className="img_responsive"/>
				<div className="Blog_main_btn_card">
					<button className="btn">JULY 29,2022</button>
				</div>
				<div className="Admin_img_and_Comments_main">
					<div className="Admin_img_and_Comments_First">
						<a href="#"><img src={user}/></a> <span>By-Admin</span>
					</div>
					<div className="Admin_img_and_Comments_second">
						<a href="#"><img src={comment}/></a> <span>0 comments</span>
					</div>
				</div>
				<div className="Content_sec_blog_head">
				<h3>Consectetur Adipiscing Elit Sedeius Mod Tempor Incididunt Ut Labore.</h3>
				</div>
                <div className="Content_sec_blog_para">
                	<p>Consectetur adipiscing elit, sed desdo eiusmod tempor incididuesdeentiut labore etesde doloesire esdesdeges magna aliquapspendisse and the gravida.</p>
                </div>
			</div>
		</div>

		<div className="col-sm-12 col-xl-4 col-lg-4 col-md-6 ">
			<div className="Blog_main_img_card_section">
				<img src={blog4} className="img_responsive img_responsive_2"/>
				<div className="Blog_main_btn_card">
					<button className="btn" id="Blog_main_btn_card_tops">JULY 29,2022</button>
				</div>
				<div className="Admin_img_and_Comments_main">
					<div className="Admin_img_and_Comments_First">
						<a href="#"><img src={user}/></a> <span>By-Admin</span>
					</div>
					<div className="Admin_img_and_Comments_second">
						<a href="#"><img src={comment}/></a> <span>0 comments</span>
					</div>
				</div>
				<div className="Content_sec_blog_head">
				<h3>Consectetur Adipiscing Elit Sedeius Mod Tempor Incididunt Ut Labore.</h3>
				</div>
                <div className="Content_sec_blog_para">
                	<p>Consectetur adipiscing elit, sed desdo eiusmod tempor incididuesdeentiut labore etesde doloesire esdesdeges magna aliquapspendisse and the gravida.</p>
                </div>
			</div>
		</div>
		<div className="col-sm-12 col-xl-4 col-lg-4 col-md-6 ">
			<div className="Blog_main_img_card_section">
				<img src={blog5} className="img_responsive img_responsive_2"/>
				<div className="Blog_main_btn_card">
					<button className="btn" id="Blog_main_btn_card_tops">JULY 29,2022</button>
				</div>
				<div className="Admin_img_and_Comments_main">
					<div className="Admin_img_and_Comments_First">
						<a href="#"><img src={user}/></a> <span>By-Admin</span>
					</div>
					<div className="Admin_img_and_Comments_second">
						<a href="#"><img src={comment}/></a> <span>0 comments</span>
					</div>
				</div>
				<div className="Content_sec_blog_head">
				<h3>Consectetur Adipiscing Elit Sedeius Mod Tempor Incididunt Ut Labore.</h3>
				</div>
                <div className="Content_sec_blog_para">
                	<p>Consectetur adipiscing elit, sed desdo eiusmod tempor incididuesdeentiut labore etesde doloesire esdesdeges magna aliquapspendisse and the gravida.</p>
                </div>
			</div>
		</div>
		<div className="col-sm-12 col-xl-4 col-lg-4 col-md-6 ">
			<div className="Blog_main_img_card_section">
				<img src={blog6} className="img_responsive img_responsive_2"/>
				<div className="Blog_main_btn_card">
					<button className="btn" id="Blog_main_btn_card_tops">JULY 29,2022</button>
				</div>
				<div className="Admin_img_and_Comments_main">
					<div className="Admin_img_and_Comments_First">
						<a href="#"><img src={user}/></a> <span>By-Admin</span>
					</div>
					<div className="Admin_img_and_Comments_second">
						<a href="#"><img src={comment}/> </a><span>0 comments</span>
					</div>
				</div>
				<div className="Content_sec_blog_head">
				<h3>Consectetur Adipiscing Elit Sedeius Mod Tempor Incididunt Ut Labore.</h3>
				</div>
                <div className="Content_sec_blog_para">
                	<p>Consectetur adipiscing elit, sed desdo eiusmod tempor incididuesdeentiut labore etesde doloesire esdesdeges magna aliquapspendisse and the gravida.</p>
                </div>
			</div>
		</div>
	</div>
	</div>
	<div className="pb-5"></div>
</div>

    </div>
  )
}

export default Blog