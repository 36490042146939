import React from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Container, FormControl, FormLabel, Stack } from "@mui/material";
import "./find-zodiac_form.css";

const Find_zodiac = () => {
  return (
    <div className="as_know_sign_wrapper">
      <Container maxWidth="lg">
      <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
        <FormControl className="as_sign_form text-left">
          <div className="form_field">
            {" "}
            <FormLabel>Date Of Birth</FormLabel>
            <TextField type="date" className="form-control"></TextField>
          </div>
          <div className="form_field">
            <FormLabel>Time Of Birth</FormLabel>
            <TextField type="text" className="form-control" color="secondary" defaultValue="Secondary" />
          </div>
          <div className="form_field">
            <FormLabel>Place Of Birth</FormLabel>
            <TextField type="text" className="form-control" color="warning" defaultValue="Warning" />
          </div>
          <Button variant="outlined" color="secondary" type="submit" className="as_btn">
            Find zodiac
          </Button>
        </FormControl>
      </Stack>
      </Container>
    </div>
  );
};

export default Find_zodiac;
